<template>
	<div id="standardData">
		<Query></Query>
		<div class="c-container-box">
			<el-table :data="dataArr">
				<el-table-column type="index" label="序号" width="100" show-overflow-tooltip></el-table-column>
				<el-table-column prop="dateTime" label="时间" width="200"></el-table-column>
				<el-table-column label="设备数据">
					<el-table-column v-for="(item,index) of sensorArr" v-if="item.isShow" :key="item.id">
						<template slot="header">
							<span>{{item.title}}</span>
						</template>
						<template slot-scope="scope">
							{{scope.row.datas[index].value}}
						</template>
					</el-table-column>
				</el-table-column>
			</el-table>
			<el-pagination background :page-sizes="[15, 20, 25, 30]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="30" v-show="dataArr.length>0">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import Query from '../../components/Query.vue';
	
	export default {
		data() {
			return {
				sensorArr: [],
				dataArr: []
			}
		},
		components: {
			Query
		},
		mounted() {
			this.sensorArr = this.common.cGetSensorData();
			this.dataArr = this.common.cGetDeviceData();
		}
	}
</script>

<style lang="scss">

</style>
